import React from "react";
import SEO from "../components/seo/SEO";
import Navbar from "../components/layout/Navbar";

const privacy = () => {
	return (
		<>
			<SEO title="Privacy" description="This is tribe-studio privacy page" />
			<Navbar />
	        <div className="overlay"></div>

			<div className="privacyPage">
				<div className="container">
					<p className="privacyTitle">COOKIE POLICY</p>
					<p className="privacyDate">Last updated April 30, 2021</p>
					<p className="privacyText">
						This Cookie Policy explains how TRB Studio AB ("
						<span className="privacyBoldSpan">Company</span>", "
						<span className="privacyBoldSpan">we</span>", "
						<span className="privacyBoldSpan">us</span>", and "
						<span className="privacyBoldSpan">our</span>") uses cookies and
						similar technologies to recognize you when you visit our websites at
						<a
							href="https://www.tribestudio.se"
							target="_blank"
							rel="noopener noreferrer"
						>
							http://www.tribestudio.se
						</a>
						, ("<span className="privacyBoldSpan">Websites</span>"). It explains
						what these technologies are and why we use them, as well as your
						rights to control our use of them.
					</p>
					<p className="privacyText">
						In some cases we may use cookies to collect personal information, or
						that becomes personal information if we combine it with other
						information.
					</p>
					<p className="privacySubTitle">What are cookies?</p>
					<p className="privacyText">
						Cookies are small data files that are placed on your computer or
						mobile device when you visit a website. Cookies are widely used by
						website owners in order to make their websites work, or to work more
						efficiently, as well as to provide reporting information.
					</p>
					<p className="privacyText">
						Cookies set by the website owner (in this case, TRB Studio AB) are
						called "first party cookies". Cookies set by parties other than the
						website owner are called "third party cookies". Third party cookies
						enable third party features or functionality to be provided on or
						through the website (e.g. like advertising, interactive content and
						analytics). The parties that set these third party cookies can
						recognize your computer both when it visits the website in question
						and also when it visits certain other websites.
					</p>
					<p className="privacySubTitle">Why do we use cookies?</p>
					<p className="privacyText">
						We use first and third party cookies for several reasons. Some
						cookies are required for technical reasons in order for our Websites
						to operate, and we refer to these as "essential" or "strictly
						necessary" cookies. Other cookies also enable us to track and target
						the interests of our users to enhance the experience on our Online
						Properties. Third parties serve cookies through our Websites for
						advertising, analytics and other purposes. This is described in more
						detail below.
					</p>
					<p className="privacyText">
						The specific types of first and third party cookies served through
						our Websites and the purposes they perform are described below
						(please note that the specific cookies served may vary depending on
						the specific Online Properties you visit):
					</p>
					<p className="privacySubTitle">How can I control cookies?</p>
					<p className="privacyText">
						You have the right to decide whether to accept or reject cookies.
						You can exercise your cookie rights by setting your preferences in
						the Cookie Consent Manager. The Cookie Consent Manager allows you to
						select which categories of cookies you accept or reject. Essential
						cookies cannot be rejected as they are strictly necessary to provide
						you with services.
					</p>
					<p className="privacyText">
						The Cookie Consent Manager can be found in the notification banner
						and on our website. If you choose to reject cookies, you may still
						use our website though your access to some functionality and areas
						of our website may be restricted. You may also set or amend your web
						browser controls to accept or refuse cookies. As the means by which
						you can refuse cookies through your web browser controls vary from
						browser-to-browser, you should visit your browser's help menu for
						more information.
					</p>
					<p className="privacyText">
						In addition, most advertising networks offer you a way to opt out of
						targeted advertising. If you would like to find out more
						information, please visit{" "}
						<a
							href="https://www.aboutads.info/choices/"
							target="_blank"
							rel="noopener noreferrer"
						>
							http://www.aboutads.info/choices/
						</a>{" "}
						or
						<a
							href="https://www.youronlinechoices.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							http://www.youronlinechoices.com
						</a>
						.
					</p>
					<p className="privacyText">
						The specific types of first and third party cookies served through
						our Websites and the purposes they perform are described in the
						table below (please note that the specific cookies served may vary
						depending on the specific Online Properties you visit):
					</p>
					<p className="privacySubTitle">Essential website cookies:</p>
					<p className="privacyText">
						These cookies are strictly necessary to provide you with services
						available through our Websites and to use some of its features, such
						as access to secure areas.
					</p>
					<div className="essentialCookiesList">
						<ul>
							<li><span className="cookiesListTitle">Name:</span> __tlbcpv</li>
							<li><span className="cookiesListTitle">Purpose:</span> Used to record unique visitor views of the consent banner.</li>
							<li><span className="cookiesListTitle">Provider:</span> .termly.io</li>
							<li><span className="cookiesListTitle">Service:</span> Termly <a href="https://termly.io/our-privacy-policy/"
							target="_blank"
							rel="noopener noreferrer"> View Service Privacy Policy</a></li>
							<li><span className="cookiesListTitle">Country:</span> United States</li>
							<li><span className="cookiesListTitle">Type:</span> http_cookie</li>
							<li><span className="cookiesListTitle">Expires in:</span> 1 year</li>
						</ul>
					</div>
					<p className="privacySubTitle">
						What about other tracking technologies, like web beacons?
					</p>
					<p className="privacyText">
						Cookies are not the only way to recognize or track visitors to a
						website. We may use other, similar technologies from time to time,
						like web beacons (sometimes called "tracking pixels" or "clear
						gifs"). These are tiny graphics files that contain a unique
						identifier that enable us to recognize when someone has visited our
						Websites or opened an e-mail including them. This allows us, for
						example, to monitor the traffic patterns of users from one page
						within a website to another, to deliver or communicate with cookies,
						to understand whether you have come to the website from an online
						advertisement displayed on a third-party website, to improve site
						performance, and to measure the success of e-mail marketing
						campaigns. In many instances, these technologies are reliant on
						cookies to function properly, and so declining cookies will impair
						their functioning.
					</p>
					<p className="privacySubTitle">
						Do you use Flash cookies or Local Shared Objects?
					</p>
					<p className="privacyText">
						Websites may also use so-called "Flash Cookies" (also known as Local
						Shared Objects or "LSOs") to, among other things, collect and store
						information about your use of our services, fraud prevention and for
						other site operations.
					</p>
					<p className="privacyText">
						If you do not want Flash Cookies stored on your computer, you can
						adjust the settings of your Flash player to block Flash Cookies
						storage using the tools contained in the{" "}
						<a
							href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
							target="_blank"
							rel="noopener noreferrer"
						>
							Website Storage Settings Panel
						</a>
						. You can also control Flash Cookies by going to the{" "}
						<a
							href="http://wwmacromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
							target="_blank"
							rel="noopener noreferrer"
						>
							Global Storage Settings Panel
						</a>{" "}
						and following the instructions (which may include instructions
						thaexplain, for example, how to delete existing Flash Cookies
						(referred to "information" on the Macromedia site), how to prevent
						FlasLSOs from being placed on your computer without your being
						asked, and (for Flash Player 8 and later) how to block Flash Cookies
						thaare not being delivered by the operator of the page you are on at
						the time).
					</p>
					<p className="privacyText">
						Please note that setting the Flash Player to restrict or limit
						acceptance of Flash Cookies may reduce or impede the functionality
						of some Flash applications, including, potentially, Flash
						applications used in connection with our services or online content.
					</p>
					<p className="privacySubTitle">Do you serve targeted advertising?</p>
					<p className="privacyText">
						Third parties may serve cookies on your computer or mobile device to
						serve advertising through our Websites. These companies may use
						information about your visits to this and other websites in order to
						provide relevant advertisements about goods and services that you
						may be interested in. They may also employ technology that is used
						to measure the effectiveness of advertisements. This can be
						accomplished by them using cookies or web beacons to collect
						information about your visits to this and other sites in order to
						provide relevant advertisements about goods and services of
						potential interest to you. The information collected through this
						process does not enable us or them to identify your name, contact
						details or other details that directly identify you unless you
						choose to provide these.
					</p>
					<p className="privacySubTitle">
						How often will you update this Cookie Policy?
					</p>
					<p className="privacyText">
						We may update this Cookie Policy from time to time in order to
						reflect, for example, changes to the cookies we use or for other
						operational, legal or regulatory reasons. Please therefore re-visit
						this Cookie Policy regularly to stay informed about our use of
						cookies and related technologies.
					</p>
					<p className="privacyText">
						The date at the top of this Cookie Policy indicates when it was last
						updated.
					</p>
					<p className="privacySubTitle">
						Where can I get further information?
					</p>
					<p className="privacyText">
						If you have any questions about our use of cookies or other
						technologies, please email us at{" "}
						<a href="mailto:info@tribestudio.se">info@tribestudio.se</a> or by
						post to:
					</p>
					<div className="bottomList">
						<ul>
						<li>TRB Studio AB</li>
								<li>Framnasbacken 7</li>
								<li>Solna, STOCKHOLMS LAN 171 66</li>
								<li>Sweden</li>
							<a
								href="https://www.google.com/maps/place/Framn%C3%A4sbacken+7,+171+66+Solna,+Sweden/@59.3564489,17.9841153,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9debfc4c4af7:0x36c8dcdd8454127d!8m2!3d59.3564462!4d17.986304?hl=en"
								target="_blank"
								rel="noopener noreferrer"
							>
								Google Map
							</a>
							<li>
								Phone: <a href="tel:+46766373740">+46766373740</a>
							</li>
						</ul>
					</div>
					<p className="privacyText">
						This cookie policy was created using{" "}
						<a
							href="https://termly.io/en/products/cookie-consent-manager/?ftseo"
							target="_blank"
							rel="noopener noreferrer"
						>
							Termly’s Cookie Consent Manager
						</a>
						.
					</p>
				</div>
			</div>
		</>
	);
};

export default privacy;
